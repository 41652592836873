<template>
  <div class="html-display">
    <div v-html="content"></div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  props: [
    'content'
  ],
  data() {
    return {
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
